<template>
  <div class="paymentHistory">
    <div class="go-back">
      <div @click="onClickLeft" style="flex: 1">
        <van-icon name="arrow-left" class="icon-left" />
        <span>全部账单</span>
      </div>
    </div>
    <div class="content">
      <div v-if="allStagesList.length == 0">
        <van-empty description="暂无付款历史" />
      </div>
      <div class="each" v-else v-for="item in allStagesList" :key="item.bill_stage_id">
        <div class="periods">{{item.current_stage}}/{{item.total_stage}}期</div>
        <div class="date">
          <div>付款日期：{{item.trade_date}}</div>
          <div style="margin-top:5px">支付模式：{{paymentModeList[item.payment_mode]}}</div>
        </div>
        <div class="price">
          <span>￥{{item.trade_amount}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { billStageRepaymentHistory } from "@/api/bill.js";
export default {
  name: "paymentHistory",

  data() {
    return {
      bill_id: "",
      allStagesList: [],
      paymentModeList:{
        0:'当期支付',
        1:'全部结清',
        2:'部分结清',
      }
    };
  },

  mounted() {
    this.bill_id = this.$route.query.bill_id;
    this.initData();
  },

  methods: {
    async initData() {
      const parme = {
        billId: this.bill_id,
      };
      const { code, data } = await billStageRepaymentHistory(parme);
      if (code == 200) {
        this.allStagesList = data
      }
    },
    onClickLeft() {
      this.$router.push({
        path: "/paymentSchedule",
        query: {
          bill_id: this.bill_id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.paymentHistory {
  height: 100%;
  width: 100%;
  font-size: 30px;
  color: #333;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .go-back {
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 30px;
    width: 100%;
    background: #fff;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    height: 100px;
  }
  .content {
    flex: 1;
    overflow: auto;
    padding: 0px 30px;
    .each {
      // margin: 30px 0px;
      padding: 30px 0px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #E7E7E7;
      .periods {
        width: 120px;
      }
      .dot {
        margin-right: 30px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #bfbfbf;
      }
      .date {
        flex: 1;
      }
      .price {
        font-size: 36px;
        font-weight: 600;
      }
    }
  }
}
</style>